import {request} from "./api";
import {getItem, setItem} from '../helpers/localStorage';
import {FLOORPLAN_NAME, TOUR_ID} from '../constants';
import {generateUUID} from '../helpers/uuid';
import FloorplanManager from '../helpers/floorplanManager';
import { setTourId, updateFloorplanOptions } from "../helpers/tour-service";
import { getUserID } from "../helpers/user-service";
import {getFloorplanItems, updateFloorplanItems} from '../helpers/tour-service';

export const create = (name) => {
    const layer = createEmptyTour();
    const data = {};
    const tourId = generateUUID();
    data.floorplan_json = layer;
    data.id = tourId;
    data.name = name;
    setTourId(tourId);

    return updateFloorplans(data);
};
  
export const get = (userID) => {  
  return request(`/p/user-data.pl?userID=${userID}`, {
    method: 'GET'
  });
};

export const getFormData =() => {
    const userID = getUserID();
    const floorplans = getFloorplanItems();
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('data', JSON.stringify(floorplans));

    return formData;
}
export const updateFloorplans = (data) => {
    if (data) updateFloorplanItems(data);

    const formData = getFormData();

    return request(`/p/user-data.pl`, {
        method: 'POST',
        body: formData
    });
}

export const updateAllFloorplans = (floorplans) => {
    const userID = getUserID();
    floorplans.forEach((floorplan) => updateLocalStorage(userID, floorplan));
}

export const updateLocalStorage = (userID, obj={}) => {
    const prefix = `floorplan_${userID}`;
    const id = `${prefix}_${obj.id}`;
    const data = getItem(id);

    setItem(id, {
        ...data,
        ...obj
    })
}

export const update = (obj={}) => {
    return updateFloorplans(obj);
};

export const deleteFloorplan = (id) => {
    return request(`/floorplan/${id}`, {
        method: 'DELETE'
    });
};

export const createEmptyTour = () => {
    const layout = new FloorplanManager();
    const layer = layout.getClearFloorplan();
    setItem(FLOORPLAN_NAME, layer);

    return layer;
}

export const updateName = (id, name) => {
    updateFloorplanOptions(id, name);
    updateFloorplans();
};