let FLOORPLANS = null;
let TOUR_ID = null;

export const getFloorplanItems = () => { 
    return [...FLOORPLANS];
}

export const setFloorplanItems = (floorplans) => { 
    FLOORPLANS = floorplans;

    return getFloorplanItems();
}


export const updateFloorplanItems = (floorplan) => { 
    const exist = FLOORPLANS.find((obj) => obj.id === floorplan.id);

    FLOORPLANS = [
        {
            ...floorplan,
            name: exist ? exist.name : floorplan.name
        },
        ...FLOORPLANS.filter((obj) => obj.id !== floorplan.id),
    ];

    return getFloorplanItems();
}


export const updateFloorplanOptions = (floorplanId, props) => { 
    const exist = FLOORPLANS.find((obj) => obj.id === floorplanId);

    FLOORPLANS = [
        {
            ...exist,
            ...props
        },
        ...FLOORPLANS.filter((obj) => obj.id !== floorplanId),
    ];

    return getFloorplanItems();
}

export const getTourId = () => { 
    return TOUR_ID;
}

export const setTourId = (tourId) => { 
    TOUR_ID = tourId;
    return getTourId();
}

export const getFlooplan = (floorplanId = getTourId()) => { 
    const exist = FLOORPLANS.find((obj) => obj.id === floorplanId);

    return exist;
}
