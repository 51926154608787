import { useEffect } from 'react';

const useBeforeUnload = (callback) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (callback && typeof callback === 'function') {
        callback(event);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [callback]); // Re-run effect if callback changes
};

export default useBeforeUnload;
