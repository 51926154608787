export const upload = async ({ formData, url, signal, onprogress, id }) => {
    return new Promise((resolve, reject) => {
        // Create a new XMLHttpRequest to handle the upload
        const xhr = new XMLHttpRequest();

        // Open a POST request to the given URL
        xhr.open('POST', url, true);

        // Set the abort signal if provided
        if (signal) {
            signal.addEventListener('abort', () => {
                xhr.abort();
                reject(new Error('Upload aborted'));
            });
        }

        // Set up the onprogress event to track the upload progress
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable && onprogress) {
                const progress = {
                    loaded: event.loaded,
                    total: event.total,
                    id
                };
                onprogress(progress);  // Call the onprogress callback with current progress
            }
        };
        
        xhr.responseType = 'blob';

        // Set up the onload event to resolve the promise when the upload is complete
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response);  // Resolve the promise with the server's response
            } else {
                reject(new Error('Network error'));
                // reject(new Error(`Failed to upload: ${xhr.statusText}`));  // Reject on failure
            }
        };

        // Set up the onerror event to reject the promise if there's an error
        xhr.onerror = () => {
            reject(new Error('Network error'));
        };

        // Send the FormData object as the request body
        xhr.send(formData);
    });
};