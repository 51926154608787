import React, { Component } from 'react';
import s from './style.scss';
import PropTypes from "prop-types";
import { getBase64FromSVG } from 'a_root-folder/utils/helper';
import {saveSvg} from 'a_root-folder/utils/browser';
import { getUserID } from '../../../demo/src/helpers/user-service';
import { DOMAIN } from '../../../demo/src/constants';
import { upload } from '../../../demo/src/api/panorama';

export default class ScreenShot extends Component {
    constructor(props) {
        super(props);

        const windowUrl = window.location.search;
        const searchParams = new URLSearchParams(windowUrl);

        this.state = {
            createPanoImg: searchParams.get("createPanoImg"),
            name: searchParams.get("name")
        }
    }

    _getScreenShots(layers){
        return new Promise((res, rej) => {
            let screenShots = [];
            function getLayers(layersKeys) {
                let item = layersKeys.splice(0,1)[0];
                if(item){
                    const name = layers.get(item).get('name')
                    setTimeout(() => {
                        getBase64FromSVG({cut: true, layer: layers.get(item), svg: true, transform: false, hideMeasurement: false}).then(({data: metricalScreenshot, offsets, height }) => {
                            screenShots.push({
                                id: item,
                                src: metricalScreenshot,
                                layerName: name,
                            });
                            getLayers.call(this, layersKeys);
                        })
                    }, 0)
                }else{
                    res({screenshots: screenShots})
                }
            }
            let arr = Array.from(layers.keys());
            getLayers.call(this, arr);
        })
    }

    componentDidMount(){
        setTimeout(() => {
            this.screenShots();
        }, 0)
    }

    screenShots(){
        let {scene} = this.props.state;
        let {layers} = scene;
        let promises = [];
        promises.push(this._getScreenShots(layers));

        Promise.all(promises).then(async (data) => {
            let obj = {};
            data.forEach((val, ind) => {
                let keys = Object.keys(val);
                keys.forEach((key)=>{
                    obj[key] = val[key];
                })
            })

            if (this.state.createPanoImg) {
                const promises = [];
                obj.screenshots.forEach( async (data) => {
                    promises.push(this.uploadOnServer(decodeURIComponent(data.src.replace('data:image/svg+xml;utf8,', ''))));
                });
                await Promise.all(promises);

                window.close();
            } else {
                obj.screenshots.forEach((data) => saveSvg(decodeURIComponent(data.src.replace('data:image/svg+xml;utf8,', ''))));
                this.context.projectActions.rollback();
            }
        });
    }

    async uploadOnServer(url) {
        const userID = getUserID();
        const tourName = this.state.name;
        const imageBlob = new Blob([url], { type: "image/svg+xml" });
        const formData = new FormData();

        formData.append('floorplan', imageBlob, `floorplan.svg`);
        formData.append('src', `${userID}/${tourName}/`);
        const controller = new AbortController();
        return upload({ formData, url: `/upload_floorplan`, signal: controller.signal, onprogress: () => {}, id: url });
    }

    _getElement(el){
        if(!el) return
        this.wrapper = el.querySelector('svg');
    }

    render() {
        return (
            <div className={s['floorplan']}>
                <div className='floorplan-body' ref={this._getElement.bind(this)}>{ this.props.children }</div>
            </div>
        );
    }
}

ScreenShot.contextTypes = {
    sceneActions: PropTypes.object.isRequired,
    messageActions: PropTypes.object.isRequired,
    projectActions:  PropTypes.object.isRequired
};

