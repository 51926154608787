import {clearToken, getToken} from "./token";
const {API_DOMAIN} = require(`../constants`);

const headersDefault = () => {
    return {
        // 'Content-Type': 'application/json',
        // 'Authorization': `${getToken()}`
    }
}

export const request = (url, options) => {
    return new Promise((res, rej) => {
      fetch(`${API_DOMAIN}${url}`, {headers: {...headersDefault(), ...options.headers}, ...options})
            .then(async (response) => {
              let data = null;

              switch(response.status) {
                  case 401:
                    clearToken();
                    const {errorDescription} = await response.json();
                    window.location.href = "/";
                    rej(errorDescription);
                    rej();
                    break;
                  case 400:
                      data = await response.json();
                      rej(data);
                    break;
                  case 403:
                    rej('Not valid user');
                    break;
                  case 304:
                  case 204:
                    res({});
                    break;
                  case 200:
                  case 201:
                    if (response.ok && response.headers.get('Content-Length') > 100) {
                        data = await response.text();

                        res(JSON.parse(data))
                    }
                    
                    res([]);
                  break;
                  default:
                  data = await response.json();
                  rej(data);
                }
            })
    })
};