import React, { useState, useContext, useRef } from 'react';
import { MdSave, MdAccountCircle, MdKeyboardArrowRight} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { setItem, getItem} from '../../helpers/localStorage';
import { FLOORPLAN_NAME } from '../../constants';
import {  setTourId } from '../../helpers/tour-service';
import { useLongPress } from 'use-long-press';
import { updateName } from '../../api/floorplan';
import LoadingContext from '../../hoc/loading/index';

const floorplan = require('../../assets/floorplan.png');

import './style.scss';

export default ({ id, floorplan_json, name, last, scrollContainerRef }) => {
    const history = useHistory();
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(name);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const startScrollLocation = useRef()

    const handleEditClick = () => {
        const movement = Math.abs(startScrollLocation.current - scrollContainerRef.current.scrollTop)
        console.log('movement', movement)
        if (movement > 20) return;

        setIsEditing(true);
    };

    const handleNameChange = (event) => {
        setEditedName(event.target.value);
    };

    const handleSaveClick = async () => {
        showLoading();
        setIsEditing(false);
        await updateName(id, {name: editedName});
        hideLoading();
    };

    const moveToPlanner = () => {
        if(isEditing) return;

        setItem(FLOORPLAN_NAME, floorplan_json);
        setTourId(id);
        history.push(`/planner`);
    };

    const bind = useLongPress(handleEditClick, {
        onStart: () => (startScrollLocation.current = scrollContainerRef.current.scrollTop),
        onFinish: () => (startScrollLocation.current = null),
        onCancel: event => {
            moveToPlanner(event);
        },
        threshold: 350,
      });

    return (
        <div className="flex-row space-between edit-page" ref={ startScrollLocation }>
            <div className={'edit-page--label'} {...bind()}>
                <img src={floorplan} />
                <div className={`edit-page--view ${last}`}>
                    {!isEditing ? 
                        (<div className={"flex-row view"}><span className="edit-page--span">{editedName}</span><MdKeyboardArrowRight /></div>)
                    :
                        (<div className='flex-row edit'>
                            <input
                                type="text"
                                value={editedName}
                                onChange={handleNameChange}
                                className="edit-input"
                                onKeyDown={(event) => event.key === 'Enter' && handleSaveClick()}
                            />
                            <MdSave onClick={handleSaveClick} className={"save"} />
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
};
