import React from 'react';
import useBeforeUnload from './hook/useBeforeUnload';
import { getFormData } from "./api/floorplan";
const {API_DOMAIN} = require(`./constants`);

export default ({children}) => {
    useBeforeUnload(() => {
        const formData = getFormData();
        navigator.sendBeacon(`${API_DOMAIN}/p/user-data.pl`, formData);
    });

    return (
        <div className="wrapper flex-column">
            {children}
        </div>
    )
}